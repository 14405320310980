@import "../../styles/mixins.scss";

.services__section {
  @include sectionFullSize;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .text__container {
    flex: 1;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 20px;

    p {
      font-weight: var(--font-normal);
      font-size: var(--normal-font-size);
      color: var(--title-color-dark);
      text-align: right;

      @include mobile {
        display: none;
      }
    }

    hr {
      width: 350px;
      border: none;
      border-top: 0.5px solid var(--title-color-dark);

      @include mobile {
        border-top: 0px;
      }
    }
  }

  .title__container {
    @include flexCenter;
    flex: 2;
    flex-direction: column;

    .title {
      display: flex;
      align-items: center;
      gap: 50px;
      transition: 0.3s;

      h1 {
        font-size: var(--welcome-font-size);
        font-weight: var(--font-light);

        @media (max-width: 1320px) {
          font-size: var(--big-font-size);
        }

        @media (max-width: 790px) {
          font-size: var(--h1-font-size);
        }

        @include mobileTight {
          font-size: 1.75rem;
        }
      }

      button {
        width: 18rem;
        height: 6rem;
        border-radius: 5rem;
        background-color: var(--primary-color);
        border: none;
        font-size: var(--h2-font-size);
        cursor: auto;

        @media (max-width: 1320px) {
          width: 15rem;
          height: 5rem;
        }

        @media (max-width: 790px) {
          width: 12rem;
          height: 4rem;
          font-size: var(--h3-font-size);
        }

        @include mobileTight {
          width: 6rem;
          height: 2rem;
          font-size: var(--smaller-font-size);
        }

        &:hover {
          font-weight: var(--font-medium);
        }
      }

      b {
        transition: 0.3s;
      }

      b:hover {
        color: var(--primary-color);
      }
    }
  }

  .services__container {
    flex: 2;
    @include flexCenter;
    margin: 0;
    min-height: 55%;
    padding-right: 3rem;
    padding-left: 3rem;

    @media (max-width: 1320px) {
      min-height: 55%;
    }

    @media (max-width: 790px) {
      min-height: 65%;
    }

    .service__card {
      @include flexCenter;

      .card__container {
        height: 20rem;

        @include mobile {
          height: 25rem;
        }

        .card__text {
          .title {
            font-size: var(--h2-font-size);
            padding-bottom: 1rem;
          }
        }
        p {
          font-size: var(--h4-font-size);
          color: var(--title-color-dark);
          transition: 0.3s;
        }
        &:hover {
          p {
            color: var(--text-color-light);
          }
        }
      }
    }
  }
}
