@import "../../styles/mixins.scss";

.section__consulting {
  @include sectionFullSize;
  @include flexCenter;
  background-color: var(--container-color);
  position: relative;
  flex-direction: row;

  .consulting__content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    padding-bottom: 10rem;

    @include mobile {
      flex-direction: column;
    }

    @include tablet {
      max-width: 80%;
    }

    @include mobile {
      max-width: 100%;
    }

    & > div {
      padding: 2rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 50%;

      @include mobile {
        max-width: 100%;
        padding-bottom: 0;
      }

      .hover__title {
        font-size: var(--big-font-size);
        color: var(--title-color-dark);
        transition: 0.3s;

        @include tablet {
          font-size: var(--h2-font-size);
        }
      }
    }

    &:hover {
      .hover__title {
        color: var(--primary-color);
      }
    }

    .consulting__description {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      p {
        font-size: var(--h2-font-size);
        color: var(--title-color-dark);

        @include desktop {
          font-size: var(--h3-font-size);
        }

        @include tablet {
          font-size: var(--h4-font-size);
        }
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .animation {
      @include mobile {
        max-width: 450px;
      }
    }
  }

  .consulting__image {
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 40%;
    width: 100%;
    z-index: 2;

    @include tablet {
      max-height: 30%;
    }

    @include mobile {
      max-height: 50%;
    }
  }
}

.scroll__down {
  animation: scroll 2s ease infinite;
  cursor: pointer;

  @include mobile {
    display: none;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(2.5rem);
  }
}
