@import "../../styles/mixins.scss";

.section__contact {
  @include sectionFullSize;
  background-color: var(--container-color);
  overflow: hidden;
  position: relative;

  .contact__container {
    height: 100%;
    display: flex;
    flex-direction: row;
    @include flexCenter;

    .contact__info {
      @include flexCenter;
      flex-direction: column;
      width: 50%;
      h1 {
        font-weight: var(--font-semi-bold);
        color: var(--title-color-dark);
        font-size: var(--big-font-size);
        line-height: 0.9;
        white-space: nowrap;
        margin: 0;
        padding: 1rem 0;

        @media (max-width: 1450px) {
          font-size: var(--h1-font-size);
        }

        @include tablet {
          font-size: var(--h1-font-size);
        }

        @include mobile {
          font-size: var(--h2-font-size);
        }

        span {
          color: var(--primary-color);
        }
      }

      p {
        color: var(--primary-color-light);
        font-size: var(--h3-font-size);
        margin-bottom: 2rem;
        padding: 0 2rem;

        @include tablet {
          font-size: var(--normal-font-size);
          margin-left: 0;
        }

        @include mobile {
          font-size: var(--small-font-size);
          padding: 0;
        }

        a {
          color: var(--primary-color-light);
          transition: 0.3s;
        }
      }

      &:hover p a {
        color: var(--primary-color);
      }
    }

    .contact__img {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 65%;
      padding: 0.5rem;
      height: 100%;

      @media (max-width: 940px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 1rem;

        @include tablet {
          border-radius: 0;
        }
      }
    }
  }
}

.footer {
  position: absolute;
  bottom: 0.75rem;
  left: 3rem;
  text-align: center;
  padding: 1rem 0;
  color: var(--title-color-dark);
  font-size: var(--small-font-size);
}
