// Mixins

@mixin mobileTight {
  @media (max-width: 390px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 738px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1366px) {
    @content;
  }
}

@mixin fullHeightWidth {
  width: 100%;
  height: 100%;
}

@mixin sectionFullSize {
  width: 100%;
  height: 100vh;
}

// Mixing for slider

@mixin sliderMobile {
  @media (max-width: 930) {
    @content;
  }
}

@mixin sliderTablet {
  @media (max-width: 1400) {
    @content;
  }
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
