@import "../../styles/mixins.scss";

.scroll__up {
    position: fixed;
    padding: 1rem;
    right: 2.5rem;
    bottom: -20%;
    background-color: var(--title-color);
    opacity: 0.8;
    border-radius: 0.4rem;
    z-index: var(--z-fixed);
    transition: 0.3s;

    @include mobile {
        right: 1rem;
    }

    &:hover {
        background-color: var(--primary-color);
    }

    .scroll__up__icon {
        font-size: 1.5rem;
        color: var(--text-color-light);

        &:hover {
            color: var(--container-color);
        }
    }

}
  
.show-scroll {
    bottom: 3rem;
}