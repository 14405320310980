@import "../../../styles/mixins.scss";

.accordion {
  height: 100%;

  .accordion__item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 750px;

    .toggle__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: var(--normal-font-size);
      padding: 1rem 2rem;
      fill: var(--title-color-dark);
      color: var(--title-color-dark);
      transition: color 0.15s ease, fill 0.15s ease;
      font-weight: var(--font-semi-bold);
      border: 0;
      background-color: transparent;
      cursor: pointer;

      p {
        padding-right: 1rem;
      }
    }

    & .toggled:hover {
      color: var(--primary-color-light);
    }

    &:first-child .toggled {
      border-bottom: 0;
    }

    & .accordion__content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out;
      color: var(--title-color-dark);
      border-bottom: 1px solid var(--primary-color-light);
      font-weight: var(--font-normal-light);
    }

    & .accordion__content__extended {
      overflow: hidden;
    }
  }
}
