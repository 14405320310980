@import "../../styles/mixins.scss";

.section__video {
  @include sectionFullSize;
  position: relative;

  h1,
  h2 {
    color: var(--title-color-light);
  }

  .company__container {
    @include flexCenter;
    flex-direction: row;
    letter-spacing: 3px;

    h1 {
      font-size: var(--welcome-font-size);

      @include tablet {
        font-size: var(--big-font-size);
      }

      @include mobileTight {
        font-size: var(--h1-font-size);
      }
    }

    &:hover .header__hover {
      transition: 0.3s;
      color: var(--primary-color);
    }
  }

  h2 {
    font-size: var(--h1-font-size);
    padding-bottom: var(--m-1);

    @include tablet {
      font-size: var(--h2-font-size);
    }

    @include mobile {
      font-size: var(--h3-font-size);
    }
  }

  .home__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-base);

    @include flexCenter;
    flex-direction: column;
    color: var(--text-color-light);

    .home__buttons {
      @include flexCenter;
      gap: 25px;

      @include mobile {
        flex-direction: column-reverse;
      }

      button {
        background-color: transparent;
        color: var(--title-color-light);
        font-size: var(--normal-font-size);
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 0.75rem;
        border: 1px solid var(--text-color-light);
        transition: 0.3s;
        width: 100%;

        &:hover {
          background-color: var(--primary-color);
        }

        @include mobile {
          padding: 1rem 6rem;
        }
      }
    }
  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}
