@import "../../styles/mixins.scss";

.section__osint {
  @include sectionFullSize;
  position: relative;

  .osint__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-base);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-right: 3rem;
    padding-left: 3rem;
    gap: 2rem;

    @include mobile {
      flex-direction: column;
    }

    @include tablet {
      padding: 3rem;
    }

    @include mobile {
      padding: 1.5rem;
    }

    .osint__title {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
      width: 60%;

      @include mobile {
        align-items: center;
        width: 90%;
      }

      h1 {
        font-size: var(--welcome-font-size);
        color: var(--text-color-light);

        @include tablet {
          font-size: var(--big-font-size);
        }

        @include mobile {
          font-size: var(--h1-font-size);
        }
      }
      .title__hover {
        transition: 0.3s;
      }

      &:hover {
        .title__hover {
          color: var(--primary-color);
        }
      }

      p {
        width: 60%;
        color: var(--text-color-light);
        font-size: var(--h2-font-size);

        @include tablet {
          font-size: var(--h3-font-size);
          width: 100%;
        }
      }
    }

    .animation {
      @include tablet {
        max-width: 250px;
      }
    }
  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}
