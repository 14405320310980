@import "../../styles//mixins.scss";

.section__pricing {
  @include sectionFullSize;
  @include flexCenter;
  flex-direction: column;

  .pricing__text {
    @include flexCenter;
    flex-direction: column;
    max-height: 35%;
    padding: 2rem;
    margin-top: 5rem;

    h1 {
      font-size: var(--welcome-font-size);
      transition: 0.3s;
      color: var(--title-color-dark);
    }

    h3 {
      font-size: var(--h2-font-size);
      max-width: 75%;
      font-weight: var(--font-medium);
      color: var(--title-color-dark);
    }

    &:hover {
      h1 {
        color: var(--primary-color);
      }
    }

    @include tablet {
      h1 {
        font-size: var(--big-font-size);
      }

      h3 {
        font-size: var(--h4-font-size);
      }
    }

    @include mobile {
      margin-top: 8rem;
      padding: 1rem;
      h1 {
        font-size: var(--h1-font-size);
      }
    }

    @include mobileTight {
      h1 {
        font-size: var(--h1-font-size);
      }

      h3 {
        font-size: var(--normal-font-size);
      }
    }
  }

  .pricing__list {
    @include flexCenter;
    max-width: 85%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;

    @include mobile {
      padding-top: 2rem;
    }

    .swiper {
      min-height: 500px;
      padding: 3rem;

      @include mobileTight {
        padding: 1rem;
      }
    }

    .pricing__card {
      .card__container {
        height: 25rem;

        .pricing {
          @include flexCenter;
          color: var(--title-color-dark);
          font-size: var(--h3-font-size);
          gap: 0.5rem;
          padding-bottom: 2rem;

          @include mobileTight {
            h4 {
              font-size: var(--small-font-size);
            }
          }
        }

        .card__services {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          gap: 1rem;
          list-style: none;
          font-size: var(--normal-font-size);

          @include tablet {
            font-size: var(--small-font-size);
          }

          @include mobile {
            font-size: var(--smaller-font-size);
          }

          @include mobileTight {
            font-size: var(--tiny-font-size);
          }

          .card__service {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
          }
        }
      }

      &:hover {
        .pricing {
          color: var(--text-color-light);
        }

        .pricing__contact {
          a {
            color: var(--text-color-light);
          }
        }
      }
    }

    .pricing__contact {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      font-size: 2rem;
      cursor: pointer;
      padding: 1rem;

      a {
        text-decoration: none;
        color: var(--title-color-dark);
        transition: 0.3s;
      }
    }
  }
}
