@import "../../styles/mixins.scss";

.section__faq {
  @include sectionFullSize;
  @include flexCenter;
  flex-direction: column;
  position: relative;

  .container {
    @include flexCenter;
    width: 100%;
    max-width: 80%;
    height: 80%;
  }

  .animation {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }
}
