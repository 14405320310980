@import "../../styles/mixins.scss";

.card__container {
  border: 1px solid var(--title-color);
  padding: 2rem;
  max-height: 400px;
  max-width: 400px;
  border-radius: 2.5rem;
  background-color: var(--container-color);
  transition: 0.3s;
  position: relative;

  @include desktop {
    .title {
      font-size: var(--h3-font-size);
    }
  }

  .card__text {
    @include flexCenter;
    flex-direction: column;

    .title {
      @include flexCenter;
      flex-direction: row;
      color: var(--title-color-dark);
      font-size: var(--h1-font-size);
      gap: 1rem;
      transition: 0.3s;

      @include mobile {
        font-size: var(--h2-font-size);
      }

      h3 {
        color: var(--title-color-dark);
        transition: 0.3s;
      }
    }
    p {
      color: var(--title-color-dark);
      transition: 0.3s;
      font-size: var(--h3-font-size);
      margin-bottom: 1rem;
    }
  }

  &:hover {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border: none;
    transform: scale(110%);

    p {
      color: var(--text-color-light);
    }

    .title {
      color: var(--text-color-light);

      h3 {
        color: var(--text-color-light);
      }
    }
  }
}
