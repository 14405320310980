@import "../../styles/mixins.scss";

.section__testimonial {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  position: relative;

  .testimonial__content {
    @include flexCenter;
    width: 30%;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;

    h2 {
      color: var(--title-color-dark);
      font-size: var(--big-font-size);
      transition: 0.3s;

      @include tablet {
        color: var(--text-color-light);
      }
    }

    p {
      color: var(--title-color-dark);
      font-size: var(--h3-font-size);

      @include tablet {
        color: var(--text-color-light);
      }

      @include mobile {
        font-size: var(--normal-font-size);
      }
    }

    &:hover {
      h2 {
        color: var(--primary-color);
      }
    }

    @include tablet {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }

  .img__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 65%;
    padding: 0.5rem;
    height: 100%;

    @include tablet {
      width: 100%;
      padding: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 1rem;

      @include tablet {
        border-radius: 0;
      }
    }
  }
}
