@import "../../styles/mixins.scss";

.sidebar {
  @include flexCenter;
  flex-direction: column;
  background-color: var(--container-color);
  color: var(--title-color-dark);

  .bg {
    z-index: var(--z-modal);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 400px;
    background: var(--container-color);

    .links {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @include fullHeightWidth;
      border-right: 3px solid var(--title-color-dark);

      .link__content {
        @include flexCenter;
        flex-direction: row;
        gap: 0.75rem;
        font-size: var(--h3-font-size);

        h2 {
          font-size: var(--h3-font-size);
        }
      }

      .links__logo {
        padding-left: 3rem;
      }
    }
  }

  button {
    background: var(--container-color);
    padding-top: 0.5rem;
    z-index: var(--z-modal);
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
}
