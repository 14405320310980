@import "./styles/mixins.scss";
/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --header-height: 3rem;

  /* Colors */
  --hue: 0;
  --sat: 0%;
  --title-color: #1b262c;
  --title-color-dark: #0b0f11;
  --primary-color: #0d7bda;
  --primary-color-light: #bbe1fa;
  --form-label-color: #8095a3;
  --text-color-dark: hsl(var(--hue), var(--sat), 46%);
  --text-color-light: #fff;
  --container-color: #fff;

  /* Fonts and typography */
  --body-font: "Roboto", sans-serif;

  /* Font sizes */
  --welcome-font-size: 6rem;
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --h4-font-size: 1.15rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* Font weights */
  --font-light: 200;
  --font-normal-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* Margins values */
  --m-0-25: 0.25rem;
  --m-0-5: 0.5rem;
  --m-0-75: 0.75rem;
  --m-1: 1rem;
  --m-1-5: 1.5rem;
  --m-2: 2rem;
  --m-2-5: 2.5rem;
  --m-3: 3rem;

  /* Z-index */
  --z-base: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

  /* Hsl */
  --main-hsl: hsla(204, 86%, 86%, 0.42);
  --main-hsl-hover: hsla(208, 89%, 45%, 0.3);
}

/* Responsive typography */
@media screen and (max-wdth: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.93rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* Base rules */
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color-dark);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

main {
  width: 100%;
  height: 100%;
}

/* Reusable css classes */
.section {
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;
  overflow: hidden;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* Layout */

.container {
  margin: 0 4rem;
  width: auto;
  height: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* Buttons */
.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1rem 5rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: var(--title-color-dark);
  transition: all ease-in-out 0.5s;
}

.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}
