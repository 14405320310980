@import "../../styles/mixins.scss";

.radio__elements {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0.5rem;
  width: 150px;
  font-size: var(--small-font-size);
  margin-right: 2rem;

  @include mobile {
    margin-right: 4rem;
  }

  @media (max-width: 440px) {
    margin-right: 1.5rem;
  }

  .radio {
    flex: 1 1 auto;
    text-align: center;

    input {
      display: none;
    }

    .name {
      @include flexCenter;
      cursor: pointer;
      border-radius: 0.5rem;
      border: none;
      padding: 0.5rem 0;
      color: var(--title-color-dark);
      transition: all 0.3s ease-in;
    }

    &:hover .name {
      color: var(--primary-color);
      font-weight: var(--font-semi-bold);
    }
  }
}

.radio__elements .radio input:checked + .name {
  background-color: var(--container-color);
  font-weight: var(--font-semi-bold);
  border: 1px solid var(--primary-color);
}
