.toggle__button {
  svg {
    path {
      stroke: var(--title-color-dark);
      transition: 0.15s;
    }
  }

  &:hover svg path {
    stroke: var(--primary-color);
  }
}
