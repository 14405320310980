@import "../../styles/mixins.scss";

.loader__container {
  position: absolute;
  @include flexCenter;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: var(--z-modal);
  flex-direction: column;
  background-color: var(--title-color-dark);
  width: 100%;

  svg {
    height: auto;
    overflow: visible;
    stroke-linejoin: round;
    stroke-linecap: round;
    touch-action: none;
    g {
      path {
        stroke: var(--text-color-light);
      }
    }
  }

  .loader__text {
    font-size: var(--big-font-size);
    color: var(--text-color-light);
    padding-top: 0.5rem;

    @include tablet {
      font-size: var(--h1-font-size);
    }

    @include mobile {
      font-size: var(--h2-font-size);
    }

    @include mobileTight {
      font-size: var(--h3-font-size);
    }
  }
}
