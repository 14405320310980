@import "../../styles/mixins.scss";

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background: transparent;

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;

    @include mobile {
      margin-left: 0.5rem;
    }

    a {
      padding: 1.25rem;
      padding-left: 2.5rem;
      transition: 0.15s;

      &:visited {
        color: var(--title-color-dark);
      }

      &:hover {
        color: var(--primary-color);
      }
    }

    .nav__components {
      @include flexCenter;
      flex-direction: row;
    }
  }
}
