.form {
  padding: 0 5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;

  .input {
    position: relative;
    .contact__input {
      width: 100%;
      border: none;
      outline: none;
      padding: 1.5rem 1.35rem calc(0.75rem - 2px) 1.35rem;
      border-radius: 1rem;
      background-color: var(--main-hsl);
      color: var(--title-color-dark);
      font-weight: var(--font-medium);
      font-size: var(--h3-font-size);
      transition: 0.3s;
    }

    &:hover .contact__input {
      cursor: pointer;
    }

    input {
      &:focus,
      &:not(:placeholder-shown) {
        background-color: var(--container-color);
        border: 1px solid var(--primary-color);

        & ~ label {
          color: var(--primary-color);
          font-weight: var(--font-medium);
          font-size: var(--tiny-font-size);
          top: 0.8rem;
          transition: translateY(0);
        }

        & ~ .contact__icon {
          color: var(--primary-color);
        }
      }
    }

    label {
      position: absolute;
      top: 60%;
      left: calc(1.35rem + 2px);
      transform: translateY(-55%);
      color: var(--form-label-color);
      transition: 0.15s;
    }

    .contact__icon {
      position: absolute;
      right: calc(1.35rem + 2px);
      top: 60%;
      transform: translateY(-60%);
      pointer-events: none;
      color: var(--form-label-color);
      font-size: var(--h3-font-size);
    }

    textarea {
      resize: none;
      width: 100%;
      min-height: 150px;

      & ~ label {
        top: 1.5rem;
        transform: none;
      }

      & ~ .contact__icon {
        top: 1.5rem;
        transform: none;
      }

      &:focus,
      &:not(:placeholder-shown) {
        background-color: var(--container-color);
        border: 2px solid var(--primary-color);

        & ~ label {
          color: var(--primary-color);
          font-weight: var(--font-medium);
          font-size: var(--tiny-font-size);
          top: 0.8rem;
          transition: translateY(0);
        }

        & ~ .contact__icon {
          color: var(--primary-color);
        }
      }
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;

    .button {
      padding: 0;
      padding: 1rem 3rem;
      flex: 1;
      min-width: 150px;
      text-align: start;
      background-color: transparent;
      color: var(--text-color-dark);
      border: 1px solid var(--text-color-dark);
      border-radius: 1rem;
      cursor: pointer;
      font-size: var(--normal-font-size);
      transition: 0.3s;
      position: relative;

      &:hover {
        background-color: var(--primary-color);
        color: var(--text-color-light);
      }

      .icon {
        top: 1rem;
        right: 1.25rem;
        position: absolute;
        font-size: var(--normal-font-size);
      }
    }
  }

  .full {
    grid-column: span 2;
  }

  .error {
    color: red;
    font-weight: bolder;
  }
}
